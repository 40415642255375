<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="150px" :model="formLabelAlign">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="名称："
              prop="name"
              :rules="[{ required: true, message: '请输入名称' }]"
            >
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="起草部门："
              :rules="[{ required: true, message: '请输入起草部门' }]"
            >
              <el-input
                placeholder="请输入起草部门"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.drafts_department"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item
              label="起草人："
              prop="drafts_man"
              :rules="[{ required: true, message: '请输入起草人' }]"
            >
              <el-input
                placeholder="请输入起草人"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.drafts_man"
              ></el-input>
            </el-form-item>
          </el-col> -->

          <!-- <el-col :span="12">
            <el-form-item label="评估标准：">
              <el-select
                class="form-inp"
                v-model="formLabelAlign.level_type"
                placeholder="请选择评估标准"
              >
                <el-option
                  v-for="item in estimate_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="等级：">
              <el-select
                v-model="formLabelAlign.level_color"
                placeholder="请选择等级"
                class="form-inp"
              >
                <el-option
                  v-for="item in color_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item label="考核分数" prop="score">
              <el-input
                type="number"
                placeholder="考核分数"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.score"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              v-if="id"
              label="状态："
              prop="status"
              :rules="[{ required: true, message: '请选择状态' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.status"
                placeholder="状态："
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item
              label="版本时间："
              prop="publish_time"
              :rules="[{ required: true, message: '请选择时间' }]"
            >
              <el-date-picker
                class="form-inp"
                value-format="yyyy-MM-dd"
                v-model="formLabelAlign.publish_time"
                type="date"
                placeholder="版本发布时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item
              label="是否需要张贴："
              prop="is_paste"
              :rules="[{ required: true, message: '请选择是否需要张贴' }]"
            >
              <el-radio
                v-for="item in paste_list"
                v-model="formLabelAlign.is_paste"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="附件：">
              <uploadingFile v-model="formLabelAlign.attachment_List" />
            </el-form-item>
          </el-col> -->

          <el-col :span="24">
            <div class="execute">
              <el-form-item label="执行周期：">
                <el-select
                  v-model="formLabelAlign.check_times"
                  placeholder="请选择执行周期"
                  class="form-inp"
                >
                  <el-option
                    v-for="item in check_times"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label-width="0px"
                v-if="formLabelAlign.check_times == 5"
                prop="times_days"
              >
                <el-input
                  style="width: 200px"
                  placeholder="请输入天数"
                  size="mini"
                  class="form-inp"
                  v-model.number="formLabelAlign.times_days"
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </div>
          </el-col>

          <el-col :span="24">
            <el-form-item label="部门：" prop="department_id">
              <BepartmentMulti ref="BepartmentMulti" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="制度说明：">
              <el-upload
                class="avatar-uploader"
                name="file"
                :action="this.$store.state.uploadImage"
                :data="{
                  path_name: 'danger_icon',
                }"
                :headers="this.$tools.config()"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :before-upload="beforeupload"
              ></el-upload>
              <quill-editor
                class="qui"
                v-model="formLabelAlign.detail"
                ref="myQuillEditor"
                :options="editorOption"
              ></quill-editor>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { regulationsaveInfo, regulationsave } from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
import BepartmentMulti from "../../components/Bepartment_multi.vue";
import uploadingFile from "../../components/uploading/uploading_File.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "system_redact",
  components: {
    Bepartment,
    uploadingFile,
    BepartmentMulti,
    quillEditor,
  },
  data() {
    let skyRuel = (rule, value, callback) => {
      if (!this.$tools.verify_sky(value)) {
        callback(new Error("请输入正确天数"));
      } else {
        callback();
      }
    };
    return {
      //上传的附件
      File_List: [],
      type_name: "保存",
      //要修改的id
      id: "",
      paste_list: [],
      color_list: [],
      estimate_type: [],
      status_list: [],
      check_times: [],
      formLabelAlign: {
        name: "",
        drafts_department: "",
        is_enable: "",
        status: "",
        begin_time: "",
        end_time: "",
        drafts_man: "",
        detail: "",
        is_paste: "",
        level_type: "",
        level_color: "",
        publish_time: "",
        attachment_List: [],
        score: 0,
        department_id: "",
        check_times: "",
        times_days: "",
      },

      //富文本信息
      editorOption: {
        placeholder: "",
        theme: "snow", // or 'bubble'
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction
              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],
              ["image"],
              ["clean"],
            ],
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      rules: {
        times_days: [
          { validator: skyRuel, trigger: "blur" },
          { required: true, message: "请输入天数" },
        ],
      },
    };
  },
  created() {
    let { id } = this.$route.query;
    this.way_show(id);
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      regulationsaveInfo(froms).then((res) => {
        let {
          info,
          color_list,
          estimate_type,
          status_list,
          paste_list,
          check_times,
        } = res.data;

        this.color_list = color_list;
        this.estimate_type = estimate_type;
        this.status_list = status_list;
        this.paste_list = paste_list;
        this.check_times = check_times;

        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            name,
            department_id,
            drafts_man,
            end_time,
            is_enable,
            begin_time,
            status,
            detail,
            attachment,
            score,
            publish_time,
            level_type,
            level_color,
            is_paste,
            drafts_department,
            times_days,
            check_times,
          } = info;
          formLabelAlign.id = id;
          formLabelAlign.name = name;
          formLabelAlign.drafts_man = drafts_man;
          formLabelAlign.end_time = end_time;
          formLabelAlign.is_enable = is_enable;
          formLabelAlign.begin_time = begin_time;
          formLabelAlign.status = status;
          formLabelAlign.detail = detail;
          formLabelAlign.score = score;
          formLabelAlign.publish_time = publish_time;
          formLabelAlign.level_type = level_type;
          formLabelAlign.level_color = level_color;
          formLabelAlign.is_paste = is_paste;
          formLabelAlign.drafts_department = drafts_department;
          formLabelAlign.times_days = times_days;
          formLabelAlign.check_times = check_times;
          formLabelAlign.attachment_List = attachment.split(",");
          let item = department_id.split(",");
          this.$refs.BepartmentMulti.way_show(item);
        }
      });
    },
    beforeupload() {
      this.$tools.shade_open();
    },

    //替换富文本上传
    uploadSuccess(res, file) {
      this.$tools.close_open();
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == 0) {
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", res.data.url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.attachment = formLabelAlign.attachment_List.join(",");
          delete formLabelAlign.attachment_List;
          let department_id = this.$refs.BepartmentMulti.way_pitch();
          console.log(department_id);
          if (!department_id) {
            this.$message({
              message: "请选择部门",
              type: "warning",
            });
          }
          formLabelAlign.department_id = department_id;
          regulationsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
  height: 32px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.form-textarea {
  width: 550px;
  margin-bottom: 10px;
}
.execute {
  display: flex;
}
.execute /deep/ .el-input__inner {
  height: 32px;
  margin-top: 1px;
}
.qui {
  height: 400px;
  margin-bottom: 40px;
}
</style>